<template>
  <div class="flex items-center w-full bg-white p-3 hlg:p-4 text-lighPurple border-b" @click="$emit('click')">
    <span class="flex-grow">{{ text }}</span>
    <ChevronRight/>
  </div>
</template>
<script>
import ChevronRight from "@/components/svg/ChevronRight"

export default {
  name: 'ListItem',
  components: {ChevronRight},

  props: {
    text: String
  }
}
</script>
